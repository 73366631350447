var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.PrintPage,
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [_c(_setup.LetterLogo)]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("main", [
        _c("h1", [_vm._v("Risikoprofil")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cover-info-bar",
            class: {
              "bg-efm": _setup.mandantInfo?.isEfm,
              "bg-verdas": _setup.mandantInfo?.isVerdas,
            },
          },
          [_vm._v("\n       \n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "my-3 d-flex align-items-center bg-red border" },
          [
            _c("img", {
              staticClass: "mx-auto",
              attrs: {
                alt: "Risikoprofil Farblegende",
                height: "auto",
                src: "content/images/cdp_color_legend_v4.svg",
                width: "70%",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }