
import { Component, Inject, Vue } from 'vue-property-decorator';
import { IAnswer } from '@/shared/model/answer.model';
import AlertService from '@/shared/alert/alert.service';
import TextFieldSettingService from '@/entities/text-field-setting/text-field-settings.service';
import { ISummaryReport } from '@/shared/model/summary-report.model';
import ReportService from '@/entities/report/report.service';
import ReportDetailPageV2 from '@/pages/report/components/report-detail-page-v2.vue';
import ReportCover from '@/pages/report/components/report-cover.vue';
import ReportOverviewPageV2 from '@/pages/report/components/report-overview-page-v2.vue';
import { Customer, ICustomer } from '@/shared/model/customer.model';
import { formatDateTimeUtil } from '@/shared/formatUtils';
import ReportMarketingCover from '@/pages/report/components/report-marketing-cover.vue';

@Component({
  components: {
    ReportMarketingCover,
    ReportCover,
    ReportOverviewPageV2,
    ReportDetailPageV2,
  },
  filters: {
    rounded(val: number): string {
      return Math.round(val) + '';
    },
  },
})
export default class ReportPreview extends Vue {
  public editMode = false;
  isLoading = false;
  public formatDateTime = formatDateTimeUtil;
  public showMarketingCover = false;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('textFieldSettingService')
  private textFieldSettingService: () => TextFieldSettingService;
  @Inject('reportService') private reportService: () => ReportService;
  private previewImage = null;
  private report: ISummaryReport = null;

  public pdfStatus = 'loading';

  get maxPages() {
    return this.customers.length + 2;
  }

  get customers(): ICustomer[] {
    return this.$store.getters.customer;
  }

  get answers(): IAnswer[] {
    return this.$store.getters.answers;
  }

  get hasUnsavedChanges(): boolean {
    const report = this.report;
    //if cca update timestamp < report update timestamp
    if (report.ccaUpdatedAt == null || report.updatedAt == null) {
      return true;
    }
    return report.ccaUpdatedAt < report.updatedAt;
  }

  /**
   * before route enter hook
   * - reset store
   * - load report
   * @param to
   * @param _from
   * @param next
   */
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      vm.resetStore();
      if (to.params.reportId) {
        vm.initReport(to.params.reportId);
      }
    });
  }

  /**
   * reset store to remove old data and show errors
   */
  resetStore() {
    //reset report store
    this.$store.commit('resetStore');
  }

  /**
   * call print function
   */
  printPages() {
    window.print();
  }

  async loadFooter(report: ISummaryReport): Promise<string | null> {
    try {
      //get main customer id from report
      let customerId = report.efmCustomerId;
      //legacy reports have no efmId
      if (!customerId) {
        console.log(
          'No efmId found in report fallback to using first customer id for footer loading'
        );
        customerId = report.reports[0].efmCustomerId;
        if (!customerId) {
          throw new Error('No customer id found in report');
        }
      }
      const res = await this.textFieldSettingService().findFooter(customerId);
      return res.data;
    } catch (e) {
      this.alertService().showError(this, 'Fehler beim Laden des Footers'); // do not block report loading and show fallback footer
      return null;
    }
  }

  async initReport(reportId: number) {
    this.isLoading = true;
    this.pdfStatus = 'loading';
    try {
      this.resetStore();
      this.report = await this.reportService().find(reportId);
      const textFields = this.report.customTextFields;
      const answers: IAnswer[] = this.transformReportToAnswers(this.report);
      //extract customer data from report and create customer objects
      const customers = this.transformReportToCustomers(this.report);
      //update store
      this.$store.commit('setCustomer', customers);
      this.$store.commit('setAnswers', answers);
      this.$store.commit('setTextField', textFields);
      this.$store.commit('setReport', this.report);
      //load footer
      const footer = await this.loadFooter(this.report);
      if (footer) {
        this.$store.commit('setFooter', footer);
      }
      this.updateStatusToReady();
    } catch (e) {
      const msg = 'Fehler beim Laden des Reports';
      this.alertService().showError(this, msg);
    } finally {
      this.isLoading = false;
    }
  }
  async updateStatusToReady() {
    this.pdfStatus = 'ready';
  }

  transformReportToAnswers(report: ISummaryReport): IAnswer[] {
    const answers: IAnswer[] = [];
    report.reports.forEach(r => {
      r.answers.forEach(a => {
        //update customer
        a.customer = { id: r.efmCustomerId };
        answers.push(a);
      });
    });
    return answers;
  }

  transformReportToCustomers(report: ISummaryReport): ICustomer[] {
    return report.reports.map(r => {
      const customer = new Customer();
      customer.id = r.efmCustomerId;
      customer.lastName = r.customerLastName;
      customer.firstName = r.customerFirstName;
      customer.city = r.customerCity;
      customer.street = r.customerStreet;
      customer.zip = r.customerZip;
      return customer;
    });
  }

  findAnswersForCustomer(customer: ICustomer): IAnswer[] {
    return this.answers.filter(a => a.customer.id === customer.id);
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  public async downloadPDF() {
    try {
      this.isLoading = true;
      const res = await this.reportService().downloadPDFFromCCA(this.report.id);
      const data = res.data;
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const link = document.createElement('a');

      link.href = url;

      let filename = res.headers['content-disposition'].split('filename=')[1];

      filename = filename.replace(/"/g, '');
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
      this.alertService().showError(this, 'Fehler beim Download des PDFs');
    } finally {
      this.isLoading = false;
    }
  }

  public async storeInCCA() {
    try {
      this.isLoading = true;
      await this.reportService().storeInCCA(this.report.id);
      await this.initReport(this.report.id);

      this.alertService().showSuccess(this, 'Dokument erfolgreich in CCA hinterlegt');
    } catch (e) {
      console.error(e);
      this.alertService().showError(this, 'Fehler beim Hinterlegen des Dokuments in CCA');
    } finally {
      this.isLoading = false;
    }
  }
}
